import {Injectable, Injector} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {catchError, first, map, Observable,throwError} from 'rxjs';
import {ErrorCode} from "../enums/error-code.enum";
import {UntilDestroy} from "@ngneat/until-destroy";
import {NotificationsService} from "../../shared/services/notifications.service";
import * as moment from "moment";

@UntilDestroy()
@Injectable()
export class ApiHttpInterceptor implements HttpInterceptor {

  constructor(private notification: NotificationsService,
              private injector: Injector) {

  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.sendRequest(req, next)
  }


  sendRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const apiReq = req.clone({
      body: this.convertDate(req?.body)
    });
    return next.handle(apiReq).pipe(
      map((res: any) => {
        if (res instanceof HttpResponse) {
          if (res.body.errorCode) {
            this.errorNotification(res);
          }
        }
        return res;
      }),
      catchError(x => this.handleAuthError(x)));
  }

  private handleAuthError(err: HttpErrorResponse): Observable<any> {
    return throwError(err);
  }

  errorNotification(res: any) {
    if (res.body.errors) {
      Object.keys(res.body.errors).forEach(key => {
        res.body.errors[key].forEach((message: string) => {
          this.notification.toasterSimple(
            "Ошибка!",
            message,
            [],
            {
              icon: 'exclamation-circle',
              iconColor: 'error-400'
            }
          ).onAction
            .pipe(first())
            .subscribe();
        })
      })
    }
    if (res.body.errorCode) {
      if (res.body.errorCode !== ErrorCode.TOKEN_EXPIRED) {
        this.notification.toasterSimple(
          "Ошибка!",
          res.body.message,
          [],
          {
            icon: 'exclamation-circle',
            iconColor: 'error-400'
          }
        ).onAction
          .pipe(first())
          .subscribe();
      }
    }
  }

  private convertDate(body: any) {
    if (body) {
      Object.keys(body).forEach(key => {
        if (body[key] instanceof Date && !isNaN(body[key].valueOf())) {
          body[key] = moment(body[key]).format('YYYY-MM-DD HH:mm:ss')
        }
      });
    }
    return body;
  }
}



