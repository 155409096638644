import {AfterContentInit, Component, OnInit} from '@angular/core';
import {SystemSettingService} from "./core/services/system-setting.service";
import {take} from "rxjs/operators";
import {Meta, Title} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterContentInit{
  title = 'mk';

  constructor(
    private _service: SystemSettingService,
    private _title: Title,
    private _meta: Meta
  ) {}

  ngOnInit() {
    this._service.getSettingSite()
      .pipe(take(1))
      .subscribe(res => {
        this._title.setTitle(res.data.name);
        this._meta.addTag({name: 'description', content: res.data.description} );
        this._meta.addTag({name: 'keywords', content: res.data.keywords} );
      })
  }

  ngAfterContentInit() {
    // window.onload = () => {
    //   appendStyle('late_styles.css');
    // };

    function appendScript() {
      //...
    }
    // function appendStyle(name: string) {
    //   let style = document.createElement("link");
    //   style.rel = "stylesheet";
    //   style.type = "text/css";
    //   style.href = name;
    //   document.head.appendChild(style);
    // }
  }

}
