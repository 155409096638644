import { Injectable } from '@angular/core';
import {ActiveToast, ToastrService} from "ngx-toastr";
import {ToasterSimpleComponent} from "../components/toaster-simple/toaster-simple.component";
import {ToasterButton} from "../components/ui-models";
import {ToasterWithButtonsComponent} from "../components/toaster-with-buttons/toaster-with-buttons.component";

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {
  activeToasters: ActiveToast<any>[] = []

  constructor(private toaster: ToastrService) { }


  open(): void {

  }

  toasterSimple(title: string, message: string, buttons?: ToasterButton[], data?: any) {
    return this.toaster.info(message, title, {
      positionClass: 'toast-top-right',
      disableTimeOut: false,
      timeOut: 10000,
      extendedTimeOut: 100000,
      toastComponent: ToasterSimpleComponent,
      toastClass: 'myToastComponent',
      payload: {
        buttons,
        data
      },
    })
  }
  toasterWithButtons(title: string, message: string, buttons?: ToasterButton[], data?: any) {
    return this.toaster.info(message, title, {
      positionClass: 'toast-top-right',
      disableTimeOut: false,
      timeOut: 10000,
      extendedTimeOut: 100000,
      newestOnTop: true,
      toastComponent: ToasterWithButtonsComponent,
      toastClass: 'myToastComponent',
      payload: {
        buttons,
        data
      },
    })
  }
}
