import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {Observable} from "rxjs";
import {IResponse} from "../models/response.models";
import {ISettingSite} from "../models/setting.models";

@Injectable({
  providedIn: 'root'
})
export class SystemSettingService {
  private apiUrl = environment.host + environment.apiVersion + '/system_settings/website';


  constructor(
    private http: HttpClient
  ) {}

  getSettingSite(): Observable<IResponse<ISettingSite>> {
    return this.http.get<IResponse<ISettingSite>>(this.apiUrl + '/site')
  }
}
